import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import usericon from "../../../assets/images/user.jpg";
import Icon from "../../../components/icon";
import {
  calculateRemainingDays,
  convertDateFormat,
} from "../../../functions/common";
import { env, env_url } from "./../../../environments/env";
import HttpServices from "./../../../environments/environment_urls";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languageOpts: [
        { value: "English", label: "English" },
        { value: "Arabic", label: "Arabic" },
      ],
      setNewHeaderLine: false,
      dropdownOpen: false,
      notifyOpen: false,
      userInfData: "",
      userOrgData: "",
      notificationList: [],
      taskOpen: false,
      taskList: [],
      pageNo: 1,
      totalRecords: 0,
      pagePerSize: 10,
      logoPath: "",
      orgName: "",
      notificationCount: 0,
      planInfo: [],
      logoutClickCount: 0,
      insideDropdownOpen: false
    };
  }

  // changeLanguage = (selectedOption) => {
  //   console.log(`Selected Language:`, selectedOption);
  // };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  loadSwitch = (m) => {
    this.props.history.push({
      pathname: "/my-tasks",
      state: { id: m.userName }
    })
    setTimeout(() => {
      this.props.history.push({
        pathname: "/switch-user-tasks",
        state: { id: m.userName }
      })
    }, 100)
  }

  onMouseEnter=()=> {
    this.setState({insideDropdownOpen: true});
  }

  onMouseLeave=()=> {
    this.setState({insideDropdownOpen: false});
  }
  toggleInsideDropdown = () => {
    this.setState({ insideDropdownOpen: !this.state.insideDropdownOpen });
  };

  toggleNotifyDropdown = () => {
    this.setState({ notifyOpen: !this.state.notifyOpen });
  };

  toggleTaskDropdown = () => {
    this.setState({ taskOpen: !this.state.taskOpen });
  };

  componentDidMount() {
    this.getMySubscriptionInfo();
    this.notificationData();
    this.getALLPendingTasks();
    this.userData();
    this.updateWindowDimensions();
    window.addEventListener("load", this.updateWindowDimensions);
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    this.setState({ logoutClickCount: 0 });
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (this.state.planInfo.planName === "Trial" && window.innerWidth <= 1365) {
      this.setState({ setNewHeaderLine: true });
    } else {
      this.setState({ setNewHeaderLine: false });
    }
  };

  userData = () => {
    const url = env_url.getLoggedInUser;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        this.setState({
          userInfData: response.data,
          userOrgData: response.data.umOrgDtl,
        });
        this.getUserOrgProperties();
      }
    });

    const logoUrl = env_url.getOrganizationInfo;
    HttpServices.getData(logoUrl).then((response) => {
      if (response?.data) {
        this.setState({
          logoPath: response.data.orgLogoPath,
          orgName: response.data.orgLegalName,
        });
      }
    });
  };

  getUserInfo = (state, props, toggleDropdown) => {
    let generatedLink = "/";
    if (sessionStorage.getItem("supplierStatus") === "Pending") {
      generatedLink = "/user-";
    }
    return (
      <Dropdown
        className={"user-dropdown " + (!state.setNewHeaderLine ? "ml-4" : "")}
        isOpen={state.dropdownOpen}
        toggle={toggleDropdown}
      >
        <DropdownToggle tag="span">
          <img
            className={"user-icon " + (state.dropdownOpen ? "active" : "")}
            src={
              state.userInfData?.photoPath
                ? `data:image/png;base64,${state.userInfData.photoPath}`
                : usericon
            }
            alt="user"
          />
        </DropdownToggle>
        <DropdownMenu className="user-dropdown-menu" right>
          {state.userInfData?.name ? (
            <React.Fragment>
              <h4 className="user-name">{state.userInfData?.name}</h4>
              <p className="user-role">
                {env.userInfo?.authorities?.some(
                  (item) => item.authority !== "ROLE_SUPPLIER_ADMIN"
                )
                  ? state.userInfData?.designation
                  : state.userInfData?.attribute1}
              </p>
            </React.Fragment>
          ) : (
            ""
          )}
          <DropdownItem divider />
          <DropdownItem
            className={
              "menu-option " +
              (window.location.pathname === "/account" ? "active" : "") +
              (window.location.pathname === "/user-account" ? "active" : "")
            }
          >
            <Link to={generatedLink + "account"}>
              <Icon iconName="user" className="mr-2" iconSize={22} />
              Profile
            </Link>
          </DropdownItem>
          {env.userInfo && env.userInfo?.authorities.some(
            (item) =>
              item.authority !== "ROLE_SUPPLIER_ADMIN" &&
              item.authority !== "ROLE_SUPPLIER_USER" &&
              item.authority !== "ROLE_SUPERADMIN" &&
              item.authority !== "ROLE_SYSADMIN"
          ) && <DropdownItem
            className={"menu-option"}
          >
              <Dropdown
                isOpen={state.insideDropdownOpen}
                toggle={this.toggleInsideDropdown}
                direction="right"
                onMouseOver={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
              >
                <DropdownToggle tag="span" caret>
                  <Icon iconName="suppliers-event" className="mr-2" iconSize={18} />
                  Switch User Tasks
                </DropdownToggle>
                <DropdownMenu>
                  {env.userInfo && env.userInfo.delegatedUsers?.length > 0 ?
                    env.userInfo.delegatedUsers.map((m) => {
                      return (<DropdownItem className={"menu-option"}>
                        <Link onClick={() => {
                          this.props.location?.pathname === "/switch-user-tasks" ?
                            this.loadSwitch(m)
                            : this.props.history.push({
                              pathname: "/switch-user-tasks",
                              state: { id: m.userName },
                            });
                        }}>
                          <Icon iconName="user" className="mr-2" iconSize={22} />
                          {m.name}
                        </Link>
                      </DropdownItem>)
                    }) :
                    <DropdownItem className={"menu-option"}>No User Tasks</DropdownItem>
                  }
                </DropdownMenu>
              </Dropdown>
            </DropdownItem>
          }
          <DropdownItem
            className={
              "menu-option " +
              (window.location.pathname === "/feedback" ? "active" : "") +
              (window.location.pathname === "/user-feedback" ? "active" : "")
            }
          >
            <Link to={generatedLink + "feedback"}>
              <Icon
                iconName="edit"
                className="mr-2"
                iconSize={20}
                style={{ paddingRight: "2px" }}
              />{" "}
              Feedback
            </Link>
          </DropdownItem>
          <DropdownItem className="menu-option" onClick={this.logOutUser}>
            <div style={{ display: "flex" }}>
              <Icon iconName="power" className="mr-2" iconSize={22} /> Logout
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  getUserOrgProperties = () => {
    env.baseCountry = this.state.userOrgData.orgCountry;
    env.baseCurrency = this.state.userOrgData.currency;
    env.dateFormat = this.state.userOrgData.dateFormat;
    env.taxName = this.state.userOrgData.defaultTax;
    env.paymentTerm = this.state.userOrgData.defaultPaymentTerms;
    env.orgId = this.state.userOrgData.id;
    env.suppId = this.state.userInfData.id;
  };

  notificationData = (pageNumber, pageSize) => {
    let notificationList = [];
    const url = env_url.getALLEmails;
    const formData = new FormData();
    formData.append("pagenum", pageNumber ? pageNumber - 1 : 0);
    formData.append("pagesize", pageSize ? pageSize : env.pageSize);
    HttpServices.postData(url, formData).then((response) => {
      if (response.data) {
        response.data.map((item) => {
          const e = {
            id: item.id,
            title: item.notifSubject,
            name: item.fromUser,
            trackId: item.id,
            dateTime: item.recievedDate,
          };
          return notificationList.push(e);
        });

        let tRecs = 0;
        if (notificationList.length > 0)
          tRecs = notificationList[0].totalRecords;

        this.setState({
          notificationList: notificationList,
          pageNo: pageNumber,
          pagePerSize: pageSize,
          totalRecords: tRecs,
        });
      }
    });
    const countUrl = env_url.getALLUnReadEmailsCount;
    HttpServices.getData(countUrl).then((response) => {
      if (response.data) {
        this.setState({ notificationCount: response.data });
      }
    });
  };

  getALLPendingTasks = () => {
    let taskList = [];
    const url = env_url.getALLPendingTasks;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        response.data.map((item) => {
          const e = {
            id: item.contextSite,
            title: item.subject,
            status: item.currentStatus,
            trackId: item.initiator,
            dateTime: item.inboxDate,
            taskName: item.taskName,
            startDate: item.startDate
          };
          return taskList.push(e);
        });
        this.setState({ taskList: taskList });
      }
    });
  };

  getMySubscriptionInfo = () => {
    const url = env_url.getMySubscriptionInfo;
    HttpServices.getData(url).then((response) => {
      if (response.data) {
        let planInfo = response.data.subscriptions[0];
        this.setState({
          planStartDate: planInfo.startDate,
          planEndDate: planInfo.expiryDate,
        });
        const subscriptionPlan =
          env_url.getSubscriptionPlanById +
          "/" +
          response.data.subscriptions[0]?.planId;
        HttpServices.getData(subscriptionPlan).then((planInfo) => {
          this.setState({
            planInfo: planInfo && planInfo.data,
          });
          setTimeout(() => {
            this.updateWindowDimensions();
          }, 500);
        });
      }
    });
  };

  logOutUser = async () => {
    this.setState((prevState) => ({
      logoutClickCount: prevState.logoutClickCount + 1,
    }));
    if (this.state.logoutClickCount === 0) {
      try {
        const url = env_url.logOut;
        await HttpServices.postData(url).then((response) => {
          if (response.data === "Logout successful") {
            sessionStorage.clear();
            window.location.href = "/signin";
          } else {
            sessionStorage.clear();
            window.location.href = "/signin";
          }
        });
      } catch (error) {
        sessionStorage.clear();
        window.location.href = "/signin";
      }
    }
  };

  handleClick = () => {
    this.setState({ taskOpen: false, notifyOpen: false });
  };

  taskList = (state, toggleTaskDropdown) => {
    return (
      <Dropdown
        className="task-dropdown"
        isOpen={state.taskOpen}
        toggle={toggleTaskDropdown}
      >
        <DropdownToggle tag="div" className="task-block p-2 mr-3">
          <Icon iconName="notification" iconSize={22} />
          <span className="task-block-count">
            {state.taskList ? state.taskList.length : ""}
          </span>
        </DropdownToggle>
        {state.taskList.length !== 0 ? (
          <DropdownMenu className="task-dropdown-menu" right>
            {state.taskList.slice(0, 4).map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DropdownItem tag="div" className="task-item">
                    <Link className="d-flex" to="/my-tasks">
                      <div className={"shortname shortname-bg-" + (index % 4)}>
                        {item.taskName
                          ? item.taskName.match(/\b(\w)/g).join("")
                          : ""}
                      </div>
                      <div className="content-description">
                        <h5 className="title mb-0">
                          {item.title ? item.title.substr(0, 30) : ""}
                        </h5>
                        <div className="label">
                          {" "}
                          {item.trackId ? item.trackId.substr(0, 35) : "NA"}
                        </div>
                        <div className="label">
                          {convertDateFormat(item.startDate, env.dateFormat)}
                        </div>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                </React.Fragment>
              );
            })}
            <div className="text-center" onClick={this.handleClick}>
              <Link className="view-all" to="/my-tasks">
                View All
              </Link>
            </div>
          </DropdownMenu>
        ) : (
          ""
        )}
      </Dropdown>
    );
  };

  notificationList = (state, toggleNotifyDropdown) => {
    return (
      <Dropdown
        className="notification-dropdown"
        isOpen={state.notifyOpen}
        toggle={toggleNotifyDropdown}
      >
        <DropdownToggle tag="div" className="notification-block p-2">
          <Icon iconName="mail-new" iconSize={22} />
          <span className="notification-block-count">
            {state.notificationCount ? state.notificationCount : 0}
          </span>
        </DropdownToggle>
        {state.notificationList.length !== 0 ? (
          <DropdownMenu className="notification-dropdown-menu" right>
            {state.notificationList.slice(0, 4).map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <DropdownItem tag="div" className="notification-item">
                    <Link className="d-flex" to="/notification-detail">
                      <div className={"shortname shortname-bg-" + (index % 4)}>
                        {item.name.match(/\b(\w)/g).join("")}
                      </div>
                      <div className="content-description">
                        <h5 className="title mb-0">
                          {item.title.substr(0, 30)}
                        </h5>
                        <div className="label mb-1">{item.name}</div>
                        <div className="label">
                          {convertDateFormat(
                            item.dateTime,
                            `${env.dateFormat} hh:mm A`
                          )}
                        </div>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider />
                </React.Fragment>
              );
            })}
            <div className="text-center" onClick={this.handleClick}>
              <Link className="view-all" to="/notification-detail">
                View All
              </Link>
            </div>
          </DropdownMenu>
        ) : (
          ""
        )}
      </Dropdown>
    );
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <Fragment>
        <header>
          <div className="header-block">
            <div className="header-content">
              <h3
                className="header-content-title d-flex align-items-center mb-0"
                style={{
                  pointerEvents: props.disableBlock ? "none" : "auto",
                  opacity: props.disableBlock ? 0.5 : 1,
                  maxWidth: "250px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <div
                  className="btn-menu-toggle"
                  onClick={() => props.handleToggleSidebar(true)}
                >
                  <Icon iconName="Paragraph mr-4" iconSize={24} />
                </div>
                {props.linkToBack && (
                  <Link to={props.linkToBack ? props.linkToBack : "/"}>
                    <Icon iconName="back-arrow mr-2" iconSize={24} />
                  </Link>
                )}
                {props.titleText ? (
                  props.titleText
                ) : state.logoPath && state.planInfo.planName !== "Trial" ? (
                  <img
                    src={`data:image/png;base64,${state.logoPath}`}
                    alt="Company Logo"
                    className="header-logo"
                  />
                ) : (
                  state.orgName
                )}
              </h3>

              {state.planInfo &&
                state.planInfo.planName === "Trial" &&
                calculateRemainingDays(state.planStartDate, state.planEndDate) >
                0 ? (
                <div className="main-screen">
                  <h3 className="header-content-title d-flex align-items-center mb-0">
                    <div className="label blink-text ml-2">
                      Trial Period expires in{" "}
                      {calculateRemainingDays(
                        state.planStartDate,
                        state.planEndDate
                      )}{" "}
                      days.
                    </div>
                    <Link
                      className="btn btn-primary btn-sm text-capitalize font-weight-normal ml-20"
                      to="/my-subscription"
                    >
                      Buy
                    </Link>
                    <a
                      href="https://prokraya.com/book-demo"
                      target="_blank"
                      className="btn btn-primary btn-sm text-capitalize font-weight-normal ml-10"
                      rel="noreferrer"
                    >
                      Request Demo
                    </a>
                  </h3>
                </div>
              ) : (
                ""
              )}

              <div className="d-flex align-items-center">
                <span
                  className="d-flex align-items-center"
                  style={{
                    pointerEvents: props.disableBlock ? "none" : "auto",
                    opacity: props.disableBlock ? 0.5 : 1,
                  }}
                >
                  {!state.setNewHeaderLine && state.userInfData?.name ? (
                    <span className="main-screen">
                      Welcome,&nbsp;
                      <b>{state.userInfData?.name?.split(" ")[0]}</b>
                      &nbsp;&nbsp;
                    </span>
                  ) : (
                    ""
                  )}

                  {this.taskList(state, this.toggleTaskDropdown)}

                  {this.notificationList(state, this.toggleNotifyDropdown)}
                </span>

                {/* {!props.supplier && (
                <div className="lang-select d-flex align-items-center mr-3 px-2">
                  <Icon iconName="language-selection" iconSize={22} />
                  <Input type="select"
                    options={state.languageOpts}
                    getChanges={this.changeLanguage}
                    isSearchable={false}
                    placeholderText="Choose Language"
                    defaultValue={state.languageOpts[0]}
                  />
                </div>
              )} */}
                {!state.setNewHeaderLine &&
                  state.userInfData &&
                  // state.planInfo?.planName &&
                  this.getUserInfo(state, props, this.toggleDropdown)}
              </div>

              {/* {(props.quickLinksList && props.quickLinksList.length > 0) && (
              <div className="nav-another-menu">
                <ul className="nav-ul-menu">
                  {props.quickLinksList.map((item, index) => {
                    return (
                      <li key={index}><Link className={item.selected ? "activate" : "menu-option"} to={item.to ? item.to : '/'}>{item.name}</Link></li>
                    )
                  })}
                </ul>
              </div>
            )} */}
            </div>
          </div>
        </header>
        {state.planInfo &&
          state.planInfo.planName === "Trial" &&
          calculateRemainingDays(state.planStartDate, state.planEndDate) > 0 ? (
          <div className="mobile-screen">
            <h3 className="header-content-title d-flex align-items-center mb-0">
              <div className="label blink-text ml-2">
                Trial Period expires in{" "}
                {calculateRemainingDays(state.planStartDate, state.planEndDate)}{" "}
                days.
              </div>
              <Link
                className="btn btn-primary btn-sm text-capitalize font-weight-normal ml-20"
                to="/my-subscription"
              >
                Buy
              </Link>
              <a
                href="https://prokraya.com/book-demo"
                target="_blank"
                className="btn btn-primary btn-sm text-capitalize font-weight-normal ml-10"
                rel="noreferrer"
              >
                Request Demo
              </a>
            </h3>
          </div>
        ) : (
          ""
        )}

        {state.setNewHeaderLine &&
          // state.planInfo?.planName &&
          state.userInfData?.name ? (
          <div className="user-block-head d-flex align-items-center justify-content-end">
            <span>
              Welcome,&nbsp;<b>{state.userInfData?.name?.split(" ")[0]}</b>
              &nbsp;&nbsp;
            </span>
            {this.getUserInfo(state, props, this.toggleDropdown)}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

export default React.memo(withRouter(Header));
