import axios from "axios";
import { env } from "./env";

// Retrieve the CSRF token from the HTML meta tags
//const csrfToken = document.querySelector('meta[name="_csrf"]').getAttribute('content');
//const csrfHeader = document.querySelector('meta[name="_csrf_header"]').getAttribute('content');

// Set the CSRF token as a default header for Axios
//axios.defaults.headers.common[csrfHeader] = csrfToken;

const HttpServices = {
  getData,
  postData,
  postFormData,
  patchData,
};

let alertCount = 0;

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=65540 ; includeSubDomains",
    "Content-Security-Policy": `default-src 'self'`,
    "X-CSRF-TOKEN": getCsrfToken(),
    "X-XSRF-TOKEN": getCsrfToken(),
    "X-Content-Type-Options": "nosniff",
    "X-XSS-Protection": "1; mode=block",
    "X-Frame-Options": "deny",
  },
});

// Function to retrieve the CSRF token from cookies
function getCsrfToken() {
  const cookieName = "X-CSRF-TOKEN"; // Replace with the actual cookie name
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    console.log("show me the csrf1111111:" + cookie);
    if (cookie.startsWith(cookieName + "=")) {
      const token = cookie.substring(cookieName.length + 1);
      console.log("show me the csrf:" + token);
      return decodeURIComponent(token);
    }
  }

  return null; // CSRF token not found
}

function getData(url) {
  const urlBody = env.baseUrl + url;
  return api({ url: urlBody, method: "GET" });
}

function postData(url, payLoad) {
  const urlBody = env.baseUrl + url;
  return api({
    url: urlBody,
    data: payLoad,
    method: "POST",
  });
}

function patchData(url) {
  const urlBody = env.baseUrl + url;
  return api({ url: urlBody, method: "PATCH" });
}

function postFormData(url, payLoad) {
  const headerProps = {
    "Content-Type": "multipart/form-data",
    "X-CSRF-TOKEN": getCsrfToken(),
    "X-XSRF-TOKEN": getCsrfToken(),
  };
  const urlBody = env.baseUrl + url;
  return api({
    url: urlBody,
    headers: headerProps,
    data: payLoad,
    method: "POST",
  });
}

const onRequest = (config) => {
  if (config.headers) {
    const token = sessionStorage.getItem("token");
    if (token !== null || token !== "" || token !== undefined) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  return config;
};

const onRequestError = (error) => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  if (error && error.response.status === 401) {
    setTimeout(() => {
      sessionStorage.clear();
      window.location.href = "/signin";
    }, 3000);
    if (alertCount === 0) {
      alert(
        "Your login session is over!! Please Login again to continue the application.."
      );
      alertCount++;
    }
  }
};

const onResponse = (response) => {
  sessionStorage.setItem("lastCallTime", new Date());
  return response;
};

const onResponseError = async (error) => {
  console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error && error.response && error.response.status === 401) {
    setTimeout(() => {
      sessionStorage.clear();
      window.location.href = "/signin";
    }, 3000);
    if (alertCount === 0) {
      alert(
        "Your login session is over!! Please Login again to continue the application.."
      );
      alertCount++;
    }
  }
};

api.interceptors.request.use(onRequest, onRequestError);
api.interceptors.response.use(onResponse, onResponseError);

export default HttpServices;
